import * as React from "react";
import Box from "@mui/material/Box";
import { TitleAction } from "../Atoms/TitleAction";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Modal } from "@mui/material";
import { FileUpladListItem } from "../Atoms/FileUpladListItem";
import { SummaryHorizontalCard } from "../Atoms/SummaryHorizontalCard";
import { withActivateDevices } from "./withActivateDevices";
import moment from 'moment';
import { withActivationStatus } from "./withActivationStatus";
import { format } from 'date-fns';

const apiUrl = localStorage.getItem("apiUrl");
//const token = process.env.REACT_APP_API_FAKE_TOKEN;

interface Statistics {
  "Error": number,
  "Ready to a activate": number,
  "In progress": number,
  "New": number,
}

interface FileUpladItem {
  fileId: number;
  fileName: number;
  totalDevices: number;
  uploadDatetime: Date;
  statistics: Statistics
}

//const epid = "DGNETSA";

interface Props {
  context: string,
}

export const DeviceManager = ({ context }: Props) => {

  const token: string | any = localStorage.getItem("idToken");

  const [fileId, setFileId] = React.useState(0);

  const [isAddDevicesModalOpen, setIsAddDevicesModalOpen] =
    React.useState(false);

  const [isActivModalOpen, setIsActivModalOpen] =
    React.useState(false);

  const handleCloseAddDeviceeModal = () => setIsAddDevicesModalOpen(false);
  const handleOpenAddDeviceModal = () => setIsAddDevicesModalOpen(true);

  const handleCloseActivModal = () =>
    setIsActivModalOpen(false);

  /**
    React.useEffect(() => {
      console.log(isActivModalOpen);
    }, [isActivModalOpen]);
   */


  const getUpladedFiles = async () => {

    const response = await axios.get(
      // `${apiUrl}/api/v1/device/csv/files/epid/${epid}`,
      context === "ADMIN" ? `${apiUrl}/api/v1/device/csv/files` : `${apiUrl}/api/v1/device/csv/files/epid/${context}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = response.data;
    return resp;
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["upladedFiles"],
    queryFn: getUpladedFiles,
  });

  React.useEffect(() => {

    refetch();
  }, [context, refetch]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }

  const ActivateDevicesForm = withActivateDevices({
    context: context,
    apiUrl: apiUrl,
    token: token,
    onCloseButton: handleCloseAddDeviceeModal,
    getUploadeFiles: getUpladedFiles,
  });

  const DeviceActivationStatusCard = withActivationStatus({
    fileId: fileId,
    token: token,
    apiUrl: apiUrl,
    onCloseButton: handleCloseActivModal,
  });

  const showActivationDetails = (fileId: number) => {
    setFileId(fileId);
    setIsActivModalOpen(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: 1,
        gap: 1,
        p: 3,
        maxWidth: "1500px",
      }}
    >
      <TitleAction
        actionName="Aktywuj urządzenia"
        title="Lista importów urządzeń "
        actionClickHandler={handleOpenAddDeviceModal}
      />
      <SummaryHorizontalCard
        value2={data?.reduce(
          (acc: number, current: FileUpladItem) => current.statistics["Ready to a activate"] + acc,
          0
        )}
        value1={data?.reduce(
          (acc: number, current: FileUpladItem) => acc + 1,
          0
        )}
        value3={data?.reduce(
          (acc: number, current: FileUpladItem) => current.statistics.Error + acc,
          0
        )}
        label1="Ilość upladów"
        label2="Aktywowane urządzenia"
        label3="Błędne urządzenia"
      />

      {data?.map((el: FileUpladItem, index: number) => (
        <FileUpladListItem
          key={index}
          fileId={el.fileId}
          fileName={el.fileName}
          totalDevices={el.totalDevices}
          uploadDatetime={format(el.uploadDatetime, 'd/MM/yyyy k:mm')}
          detailsClicked={showActivationDetails}
          statistics={el.statistics}
        />
      ))}
      <Modal
        open={isAddDevicesModalOpen}
        onClose={handleCloseAddDeviceeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <ActivateDevicesForm />
        </Box>
      </Modal>

      <Modal
        open={isActivModalOpen}
        onClose={handleCloseActivModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <DeviceActivationStatusCard />
        </Box>
      </Modal>
    </Box>
  );
};
