import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
  Theme,
  containedGreenButton,
  outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import { Device, ImportSummary } from "./Interfaces";
import { InfoCard } from "../Atoms/InfoCard";
import ErrorIcon from '@mui/icons-material/Error';
import WifiIcon from "@mui/icons-material/Wifi";

interface Props {
  headerText?: string;
  importSummary: ImportSummary;
  onCloseButton?: any;
  onReactivate?: any;
  deviceList: Device[];
}

export const DeviceActivationStatusBox = ({
  headerText,
  importSummary,
  onCloseButton,
  onReactivate,
  deviceList,
}: Props) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "1000px",
        width: "35.2vw",
        /** 
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        */
        overflow:"auto",
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
          
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">{`EPID: ${importSummary.epid}`}</Typography>
            <Typography variant="h5">{`Nazwa importowanego pliku: ${importSummary.filename}`}</Typography>
            <Typography variant="h5">{`Ilość importowanych urządzeń: ${importSummary.totalDevices}`}</Typography>
            <Typography variant="h5">{`Data importu: ${importSummary.insertedDatetime}`}</Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap:10 }} >
            {deviceList.map((el: Device) => (
              <InfoCard
                header={`nazwa:${el.deviceName}; EUI:${el.devEui}`}
                text={`opis: ${el.deviceDescription}; profil:${el.deviceProfileName}; hierarchia:${
                  el.deviceHierarchy
                }; klucz:${el.nwkKey}; aktywny:${el.isDisabled}; status:${el.status}; opis błędu:${
                  el.errorMessage ? el.errorMessage : "brak"
                }`}
                icon={el.errorMessage ?
                    <ErrorIcon
                    sx={{
                      fontSize: 26,
                      color: "#db2251",
                    }}
                  />
                  :
                  <WifiIcon
                    sx={{
                      fontSize: 26,
                      color: "#363636",
                    }}
                  />
                }
              />
            ))}
          </Box>

          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onCloseButton}
              sx={{
                ...outlinedAcceptBlackButton,
                alignSelf: "flex-end",
                mt: "32px",
                mr: "18px",
              }}
            >
              Zamknij
            </Button>
            <Button
              onClick={onReactivate}
              sx={{
                ...containedGreenButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Reaktywuj
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
