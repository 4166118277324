import * as React from "react";
import Box from "@mui/material/Box";
import { Theme, containedGreenButton } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Interface } from "readline";

interface Props {
  title?: string;
  actionName?: string;
  actionClickHandler?: any;
  isButton?: boolean;
}

export const TitleAction = ({
  title = "Profile i klasy licencji",
  actionName = "Dodaj profill",
  actionClickHandler,
  isButton = true
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: "2",
        justifyContent: "flex-start",
        alignItems: "center",
        mb: "44px",
      }}
    >
      <Typography variant="h4" fontWeight={900}>
        {title}
      </Typography>
      <Box
        sx={{
          ml: "5%",
        }}
      >
        {isButton &&
          <Button
            onClick={actionClickHandler}
            sx={{
              ...containedGreenButton,
              width: 180,
              height: 50,
              alignSelf: "flex-end",
              mt: "32px",
            }}
          >
            {actionName}
          </Button>
        }
      </Box>
    </Box>
  );
};
