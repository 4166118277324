import Box from "@mui/material/Box";
import React from "react";
import { FormLabel } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import NearbyErrorIcon from '@mui/icons-material/NearbyError';

interface Props {
  value1: number;
  value2: number;
  value3?: number;
  label1: string;
  label2: string;
  label3?: string;
  icon1?: any;
  icon2?: any;
  icon3?: any;
}

export const SummaryHorizontalCard = ({
  value1,
  value2,
  value3,
  label1,
  label2,
  label3,
  icon1 = (
    <UploadFileIcon
      sx={{
        fontSize: 30,
        color: "#363636",
        bgcolor: "#2bfece",
        width: "86px",
        height: "77px",
        borderRadius: "26px",
        ml: "31px",
        mt: "26px",
      }}
    />
  ),
  icon2 = (
    <SpeakerPhoneIcon
      sx={{
        fontSize: 30,
        color: "#363636",
        bgcolor: "#2bfece",
        width: "86px",
        height: "77px",
        borderRadius: "26px",
        ml: "31px",
        mt: "26px",
      }}
    />
  ),
  icon3 = (
    <NearbyErrorIcon
      sx={{
        fontSize: 30,
        color: "#363636",
        bgcolor: "#2bfece",
        width: "86px",
        height: "77px",
        borderRadius: "26px",
        ml: "31px",
        mt: "26px",
      }}
    />
  ),
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "311px",
          bgcolor: "white",
          height: "131px",
          borderRadius: "25px",
        }}
      >
        <Box>{icon1}</Box>
        <Box
          sx={{
            width: "120px",
            height: "81px",
            bgcolor: "white",
            mt: "21px",
            ml: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormLabel
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: "900",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.22",
              letterDpacing: "0.16px",
              textAlign: "left",
              color: "#9d9d9d",
            }}
          >
            {label1}
          </FormLabel>
          <FormLabel
            sx={{
              fontSize: "26px",
              fontFamily: "Lato",
              letterSpacing: "0.31px",
              color: "#363636",
            }}
          >
            {value1}
          </FormLabel>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "311px",
          bgcolor: "white",
          height: "131px",
          borderRadius: "25px",
          ml: "26px",
        }}
      >
        <Box>{icon2}</Box>
        <Box
          sx={{
            width: "120px",
            height: "81px",
            bgcolor: "white",
            mt: "21px",
            ml: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormLabel
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: "900",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.22",
              letterDpacing: "0.16px",
              textAlign: "left",
              color: "#9d9d9d",
            }}
          >
            {label2}
          </FormLabel>
          <FormLabel
            sx={{
              fontSize: "26px",
              fontFamily: "Lato",
              letterSpacing: "0.31px",
              color: "#363636",
            }}
          >
            {value2}
          </FormLabel>
        </Box>
      </Box>
      {value3 && (
        <Box
          sx={{
            display: "flex",
            width: "311px",
            bgcolor: "white",
            height: "131px",
            borderRadius: "25px",
            ml: "26px",
          }}
        >
          <Box>{icon3}</Box>
          <Box
            sx={{
              width: "120px",
              height: "81px",
              bgcolor: "white",
              mt: "21px",
              ml: "26px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormLabel
              sx={{
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: "900",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "2.22",
                letterDpacing: "0.16px",
                textAlign: "left",
                color: "#9d9d9d",
              }}
            >
              {label3}
            </FormLabel>
            <FormLabel
              sx={{
                fontSize: "26px",
                fontFamily: "Lato",
                letterSpacing: "0.31px",
                color: "#363636",
              }}
            >
              {value3}
            </FormLabel>
          </Box>
        </Box>
      )}
    </Box>
  );
};
