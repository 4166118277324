import * as React from "react";
import Box from "@mui/material/Box";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TitleAction } from "../Atoms/TitleAction";
import { Modal } from "@mui/material";
import { withCreateSingleDevice } from "./withCreateSingleDevice";
import { AddSingleDeviceForm } from "./AddSingleDeviceForm";
const drawerWidth = 240;

const apiUrl = localStorage.getItem("apiUrl");



interface Props {
  context?: string,
}

export const SingleDeviceManager = ({ context }: Props) => {

  const token: string | any = localStorage.getItem("idToken");

  const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] =
    React.useState(false);

  const handleCloseAddDeviceModal = () =>
    setIsAddDeviceModalOpen(false);
  const handleOpenAddDeviceModal = () =>
    setIsAddDeviceModalOpen(true);


  const getDeviceStatus = async () => {
    const response = await axios.get(
      `${apiUrl}/api/v1/licenceClass`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = response.data;
    return resp;
  };



  const { isLoading, isError, data, error, isSuccess } = useQuery({
    queryKey: ["device"],
    queryFn: getDeviceStatus,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }


  const CreateSingleDeviceForm = withCreateSingleDevice({
    epid: context,
    apiUrl: apiUrl,
    token: token,
    onCloseButton: handleCloseAddDeviceModal,
  });


  return (

    <Box

      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: 1,
        gap: 1,
        p: 3,
        minWidth: "1000px",
      }}
    >
      <TitleAction
        actionName="Dodaj urządzenie"
        title="Dodaj oraz sprawdz status"
        actionClickHandler={handleOpenAddDeviceModal}
      />

      {/**data?.map((el: LicenseClassItem, index: number) => (
        <LicenseListItem
          key={index}
          isProfile={false}
          licenceClassId={el?.id}
          licenceClassDescriptio={el?.description}
          deviceProfileId={format(el?.insertedDateTime, 'd/MM/yyyy k:mm')}
          deviceProfileName={format(el?.updatedDateTime, 'd/MM/yyyy k:mm')}
          isActive={el?.isActive}
          showvertIcon={true}
        />
      ))*/}

      <Modal
        open={isAddDeviceModalOpen}
        onClose={handleCloseAddDeviceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <CreateSingleDeviceForm />

        </Box>
      </Modal>
    </Box>

  );
};
