import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
  Theme,
  containedGreenButton,
  outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";

interface Props {
  headerText?: string;
  info?: string;
  onCloseButton?: any;
  onSaveButton?: any;
}

export const AddLicenseClassBox = ({
  headerText,
  info,
  onCloseButton,
  onSaveButton,
}: Props) => {
  const [isActive, setIsActive] = useState(true);
  const [inputValue, setinputValue] = useState("");

  /** 
  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);
  */
  const saveButtonHandler = () =>
    onSaveButton.mutate({
      description: inputValue,
      isActive: isActive,
    });

  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "900px",
        width: "35.2vw",
        /** 
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        */
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">{info}</Typography>
            {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
          </Box>

          <InputIconTextItem
            label="Nazwa klasy licencji"
            text="np. Pierwsza klasa licencji"
            icon={null}
            customWidth="80%"
            getValue={(val: string) => setinputValue(val)}
          />

          <Stack direction="row" spacing={1} alignItems="center">
            {isActive ? (
              <Typography variant="body1">TAK</Typography>
            ) : (
              <Typography variant="body1">NIE</Typography>
            )}

            <Switch
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              //name={key}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#2bfece",
                  "&:hover": {
                    backgroundColor: "#2bfece",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#2bfece",
                },
              }}
            />
            <Typography variant="body1">Aktywny ?</Typography>
          </Stack>

          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onCloseButton}
              sx={{
                ...outlinedAcceptBlackButton,
                alignSelf: "flex-end",
                mt: "32px",
                mr: "18px",
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={saveButtonHandler}
              sx={{
                ...containedGreenButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Zapisz
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
