import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { IconMenu } from "./IconMenu";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  epid: string;
  liceceClassDescription: string;
  licenseStatus: string;
  amountPerEpid: number;
  amountPerLicenceClass: number;
  amountPerStatus: number;
  filtereByEpid?: boolean;
  showvertIcon?: boolean;
}

export const LicenseStatsListItem = ({
  epid,
  liceceClassDescription,
  licenseStatus,
  amountPerEpid,
  amountPerLicenceClass,
  amountPerStatus,
  filtereByEpid = true,
  showvertIcon = true,
}: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {showvertIcon && (
          <Box
            sx={{
              // bgcolor: 'black',
              ml: "16px",
              width: "10%",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#f2f2f2",
                width: 44,
                height: 44,
              }}
            >
              <MapOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "20%",
            }}
          >
            <Typography variant="h5" noWrap>
              Epid
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {epid}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <Typography variant="h5">Klasa licencji</Typography>
            <Typography variant="h5" fontWeight={900}>
              {liceceClassDescription}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <Typography variant="h5">Status</Typography>
            <Typography variant="h5" fontWeight={900}>
              {licenseStatus}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "20%",
            }}
          >
            <Typography variant="h5">
              {filtereByEpid ? "Liczba" : "Liczba"}
            </Typography>
            <Typography variant="h5" fontWeight={900}>
              {filtereByEpid ? amountPerStatus : amountPerStatus}
            </Typography>
          </Box>

          <Box
            sx={{
              mr: "24px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#2bfece",
                width: 44,
                height: 44,
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: 34,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
