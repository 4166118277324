import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { IconMenu } from "./IconMenu";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface Statistics {
  "Error": number,
  "Ready to a activate": number,
  "In progress": number,
  "New": number,
}

interface Props {
  fileId: number;
  fileName: number;
  totalDevices: number;
  uploadDatetime: string;
  showvertIcon?: boolean;
  detailsClicked?: any;
  statistics: Statistics;
}

export const FileUpladListItem = ({
  fileId,
  fileName,
  totalDevices,
  uploadDatetime,
  showvertIcon = true,
  detailsClicked,
  statistics,
}: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {showvertIcon && (
          <Box
            sx={{
              // bgcolor: 'black',
              ml: "16px",
              width: "7%",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#f2f2f2",
                width: 44,
                height: 44,
              }}
            >
              <MapOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            ml: "2%",
            width: "13%",
          }}
        >
          <Typography variant="h5">"Numer pliku"</Typography>
          <Typography variant="h5" fontWeight={900}>
            {fileId}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "80%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "22%",
            }}
          >
            <Typography variant="h5" noWrap>
              Nazwa pliku
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {fileName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "22%",
            }}
          >
            <Typography variant="h5" noWrap>
              Liczba urządzeń
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {totalDevices}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "22%",
            }}
          >
            <Typography variant="h5" noWrap>
              Liczba aktywowanych
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {statistics["Ready to a activate"]}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "22%",
            }}
          >
            <Typography variant="h5" noWrap>
              Data upladu
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {uploadDatetime}
            </Typography>
          </Box>

          {statistics?.["In progress"]>> 0 ?
          
          <Box
            sx={{
              mr: "24px",
            }}
          >
            <IconButton onClick={()=> detailsClicked(fileId)}>
          <CircularProgress sx={{color:"#2bfece"}} />
          </IconButton>
          </Box>
        
        :
          statistics.Error > 0 ?
          
          <Box
            sx={{
              mr: "24px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#2bfece",
                width: 44,
                height: 44,
              }}
            >
              <IconButton onClick={()=> detailsClicked(fileId)}>
                <ErrorOutlineOutlinedIcon
                  sx={{
                    fontSize: 34,
                    color: "#363636",
                  }}
                />
              </IconButton>
            </Avatar>
          </Box>
          : 
          <Box
            sx={{
              mr: "24px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#2bfece",
                width: 44,
                height: 44,
              }}
            >
              <IconButton onClick={()=> detailsClicked(fileId)}>
                <CheckIcon
                  sx={{
                    fontSize: 34,
                    color: "#363636",
                  }}
                />
              </IconButton>
            </Avatar>
          </Box>
          }
        </Box>
      </ThemeProvider>
    </Box>
  );
};
