import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { IconMenu } from "./IconMenu";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from "@mui/material";

interface Props {
    importFileName: string,
    showvertIcon?: boolean,
    onClickIcon?: any,
}

export const FileListItem = ({
    importFileName,
    showvertIcon = true,
    onClickIcon
}: Props) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: 92,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
                borderRadius: "14px",
            }}
        >
            <ThemeProvider theme={Theme}>
                {showvertIcon && (
                    <Box
                        sx={{
                            // bgcolor: 'black',
                            ml: "16px",
                            width: "10%",
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#f2f2f2",
                                width: 44,
                                height: 44,
                            }}
                        >
                            <MapOutlinedIcon
                                sx={{
                                    fontSize: 26,
                                    color: "#363636",
                                }}
                            />
                        </Avatar>
                    </Box>
                )}

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        ml: "2%",

                    }}
                >
                    <Typography variant="h5">Nazwa pliku importu</Typography>
                    <Typography variant="h5" fontWeight={900}>
                        {importFileName}
                    </Typography>
                </Box>


                <Box
                    sx={{
                        mr: "24px",
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: "#2bfece",
                            width: 44,
                            height: 44,
                        }}
                    >
                        <IconButton onClick={() => onClickIcon(importFileName)}>
                            <FileDownloadIcon
                                sx={{
                                    fontSize: 34,
                                    color: "#363636",
                                }}
                            />
                        </IconButton>
                    </Avatar>
                </Box>


            </ThemeProvider>
        </Box>
    );
};
