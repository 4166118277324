import * as React from "react";
import {
    useQuery,
    useMutation,
    useQueryClient,

} from "@tanstack/react-query";
import axios from "axios";
import { AccessRights } from "./Utils";
import { GetAccessTokenForm } from "./GetAccessTokenForm";

interface Props {

    apiUrl?: string | null;
    token?: string | undefined;
    onCloseButton: any;

}

export const withGetAccessToken = ({

    apiUrl,
    token,
    onCloseButton,

}: Props) => {

    return (props: any) => {

        const [accessRights, setAccessRight] = React.useState<AccessRights>({
            readAccess: "test.*",
            writeAccess: "test*",
            configureAccess: "test*",
            clientID: "1a7f1aa5-fdda-4282-b6cf-f1669f8a61f7",
            clientSecret: "6C18F91F-F483-4185-92D9-5C36B14B2B9F"
        })

        /** 
        React.useEffect(() => {

            console.log(accessRights);
        }, [accessRights]);
        */

        const handleCopyToClipboard = (text: string) => {

            const parser = new DOMParser();
            const doc = parser.parseFromString(text, "text/xml");
            const token = doc.getElementsByTagName("token")[0].childNodes[0].nodeValue;

            navigator.clipboard.writeText(token ? token : "brak")
                .then(() => {
                    alert('Klucz zostal skopiowany');
                })
                .catch((error) => {
                    console.error('Błąd kopiowania do schowka:', error);
                    alert('Błąd kopiowania do schowka, proszę spróbuj ponownie.');
                });

        };


        const fetchAccessToken = () => {
            const url = localStorage.getItem("rabbitAccessToken");
            const API_URL = `${url}/\$token?scope=rabbitmq%20rabbitmq.write:*/*/${accessRights.writeAccess}%20rabbitmq.configure:*/*/${accessRights.configureAccess}%20rabbitmq.read:*/*/${accessRights.readAccess}&client_id=${accessRights.clientID}&client_secret=${accessRights.clientSecret}&grant_type=client_credentials`;
            //const API_URL = `https://epid.engi.pl/api-tapir/epid.cgi/\$token?scope=rabbitmq%20rabbitmq.write:*/*/JTEAM.Katowice.*%20rabbitmq.configure:*/*/JTEAM.*%20rabbitmq.read:*/*/JTEAM.MARIANKI.*&client_id=1a7f1aa5-fdda-4282-b6cf-f1669f8a61f7&client_secret=6C18F91F-F483-4185-92D9-5C36B14B2B9F&grant_type=client_credentials`;
            //const API_URL = `https://epid.engi.pl/api-tapir/epid.cgi/\$token`;
            axios.post(
                `${API_URL}`,

                {
                    headers: {
                        //               Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },


                }
            ).then((response) => {

                onCloseButton();
                handleCopyToClipboard(response.data);
            }).catch((err) => {

                console.log(err)
                alert(`Wystąpił błąd ${err}. Prawdopodobnie wprowadzony EPID lub para clientID / clientSecret są nieprawidłowe`);
            });

        };

        //handleCopyToClipboard(data.token);

        return (
            <GetAccessTokenForm selectRight={setAccessRight} onCloseButton={onCloseButton} onSaveButton={fetchAccessToken} />
        );
    };
};
