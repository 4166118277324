import * as React from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { AddLicenseClassBox } from "./AddLicenseClassBox";

interface LicenseClassItem {
  description: string;
  isActive: boolean;
}

interface Props {
  apiUrl: string | null;
  token: string | undefined;
  onCloseButton: any;
  getLicenseClasses: any;
}

export const withCreateLicenseClassItem = ({
  apiUrl,
  token,
  onCloseButton,
  getLicenseClasses,
}: Props) => {
  return (props: any) => {
    const licenseClassItem: LicenseClassItem = {
      description: "Trzecia Klasa Licencji",
      isActive: true,
    };

    const postLicenseClass = async (item: LicenseClassItem) => {

      try {
        const { data } = await axios.post(`${apiUrl}/api/v1/licenceClass`, item, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      } catch (error: any) {
        alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
        return <span>Error: {error.message}</span>;
      }
    };

    const queryClient = useQueryClient();

    const query = useQuery({ queryKey: ["todos"], queryFn: getLicenseClasses });

    const mutation = useMutation({
      mutationFn: postLicenseClass,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["licenseClasses"] });
        onCloseButton();
      },
    });
    return (
      <AddLicenseClassBox
        headerText="Dodaj klasę licencji"
        info="Wprowadź nazwę nowej klasy licencji oraz określ jej status"
        onCloseButton={onCloseButton}
        onSaveButton={mutation}
      />
    );
  };
};
