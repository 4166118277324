import React, { useState } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { LicenseClassItem } from "../ProfileManager/Interfaces";
import { AddLicenceForm } from "./AddLicenceForm";
import { v4 as uuid } from "uuid";

interface LicenseItem {
  trxId: string;
  epid: string;
  licenceClassId: number;
  quantity: number;
}

interface Props {
  apiUrl: string | null;
  token: string | undefined;
  onCloseButton: any;
  getLicenses?: any;
}

export const withAddLicenses = ({
  apiUrl,
  token,
  onCloseButton,
  getLicenses,
}: Props) => {
  return (props: any) => {
    const [licenseId, setLicenseId] = useState(1);

    const postAddLicenses = async (item: LicenseItem) => {
      const items: LicenseItem[] = [item];

      try {
        const { data } = await axios.post(`${apiUrl}/api/v1/licence`, items, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      } catch (error: any) {
        alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
        return <span>Error: {error.message}</span>;

      }
    };

    const getLicenseClasses = async () => {
      const response = await axios.get(
        `${apiUrl}/api/v1/licenceClass`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const resp = response.data;
      return resp;
    };

    const queryClient = useQueryClient();

    const addLicenseMutation = useMutation({
      mutationFn: postAddLicenses,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["licenses"] });
        onCloseButton();
      },
    });

    const onSaveLicense = (item: LicenseItem, licenseClassId: number) => {
      const licenseItemBody: LicenseItem = {
        trxId: uuid(),
        epid: item.epid,
        licenceClassId: licenseClassId,
        quantity: Number(item.quantity),
      };

      addLicenseMutation.mutate(licenseItemBody);
    };

    const { isLoading, isError, data, error } = useQuery({
      queryKey: ["licenseClasses"],
      queryFn: getLicenseClasses,
    });

    if (isLoading) {
      return <span>Loading...</span>;
    }

    if (isError) {
      alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
      return <span>Error: {error.message}</span>;
    }

    //console.log(data);

    return (
      <AddLicenceForm
        headerText="Dodaj licencje"
        info="Wprowadź informacje dotyczące ilości licencji"
        onCloseButton={onCloseButton}
        onSaveButton={onSaveLicense}
        licenceClasses={data.filter(
          (el: LicenseClassItem) => el.isActive === true
        )}
      />
    );
  };
};
