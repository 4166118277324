import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
    Theme,
    containedGreenButton,
    outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, FormControl, InputLabel, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import { SelectItem } from "../Atoms/SelectItem";
import { LicenseClassItem } from "../ProfileManager/Interfaces";
import { DeviceItem, ProfileResult } from "./Interfaces";

interface Props {
    epid?: string;
    headerText?: string;
    info?: string;
    onCloseButton?: any;
    onSaveButton?: any;
    profilesList: ProfileResult[];
}

export const AddSingleDeviceForm = ({
    epid,
    headerText,
    info,
    onCloseButton,
    onSaveButton,
    profilesList
}: Props) => {


    const [deviceItem, setDeviceItem] = React.useState<DeviceItem>({
        epid: "np. Jteam",
        deviceProfileName: "np. Dragino",
        devEui: "1234567890aabbcc",
        deviceDescription: "string",
        isDisabled: false,
        deviceName: "string",
        skipFcntCheck: true,
        deviceHierarchy: "string",
        nwkKey: "9221cacc03af14a0864b8cbd4f28f8a6"
    });

    const [isDesibled, setIsDesibled] = useState(false);

    const [skipFcntCheck, setSkipFcntCheck] = useState(true);
    const [profileId, setprofileId] = useState<string>();


    /** 
    useEffect(() => {
        setDeviceItem((currentItem) => ({
            ...currentItem,
            isDisabled: !isDesibled,
        }))

    }, [isDesibled]);
    */

    useEffect(() => {
        setDeviceItem((currentItem) => ({
            ...currentItem,
            skipFcntCheck: skipFcntCheck,
        }))

    }, [skipFcntCheck]);

    const saveButtonHandler = () => {
        onSaveButton.mutate(deviceItem);
    };
    return (
        <Box
            sx={{
                bgcolor: "white",
                maxHeight: "80vh",
                width: "35.2vw",
                overflowY: "auto",
                overflowX: "clip",
                borderRadius: 4,
                padding: 3,
                boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: 3,
                    p: 2,
                }}
            >
                <ThemeProvider theme={Theme}>
                    <Box>
                        <Typography
                            variant="h4"
                            lineHeight={1}
                            letterSpacing={0.22}
                            fontSize="24px"
                        >
                            {headerText}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5">{info}</Typography>
                        {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
                    </Box>

                    <InputIconTextItem
                        label="Epid"
                        text={epid ? epid : deviceItem.epid}
                        disabled={epid ? true : false}
                        icon={null}
                        customWidth="80%"
                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                epid: val,
                            }))
                        }
                    />


                    <Box
                        sx={{
                            width: "86%",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >

                        <SelectItem
                            availOptions={profilesList?.map(
                                (val: ProfileResult) => val.name
                            )}
                            label="Wybierz profil"
                            changeHandler={(val: string) => setDeviceItem((currentItem) => ({
                                ...currentItem,
                                deviceProfileName: val,
                            }))}
                            customwidth="280px"
                        />



                    </Box>

                    <InputIconTextItem
                        label="Dev EUI"
                        icon={null}
                        customWidth="80%"
                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                devEui: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="Opis"

                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                deviceDescription: val,
                            }))
                        }
                    />
                    {/** 
                    <Stack direction="row" spacing={1} alignItems="center">
                        {isDesibled ? (
                            <Typography variant="body1">TAK</Typography>
                        ) : (
                            <Typography variant="body1">NIE</Typography>
                        )}

                        <Switch
                            checked={isDesibled}
                            onChange={() => setIsDesibled(!isDesibled)}
                            //name={key}
                            sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#2bfece",
                                    "&:hover": {
                                        backgroundColor: "#2bfece",
                                    },
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#2bfece",
                                },
                            }}
                        />
                        <Typography variant="body1">Nieaktywny ?</Typography>
                    </Stack>
                    */}
                    <InputIconTextItem
                        label="Nazwa urządzenia"

                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                deviceName: val,
                            }))
                        }
                    />

                    <Stack direction="row" spacing={1} alignItems="center">
                        {skipFcntCheck ? (
                            <Typography variant="body1">TAK</Typography>
                        ) : (
                            <Typography variant="body1">NIE</Typography>
                        )}

                        <Switch
                            checked={skipFcntCheck}
                            onChange={() => setSkipFcntCheck(!skipFcntCheck)}
                            //name={key}
                            sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#2bfece",
                                    "&:hover": {
                                        backgroundColor: "#2bfece",
                                    },
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#2bfece",
                                },
                            }}
                        />
                        <Typography variant="body1">Pomiń sprawdzanie FCNT ?</Typography>
                    </Stack>

                    <InputIconTextItem
                        label="Hierarchia"
                        text="np. JTEAM/Katowice/Brynowska"
                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                deviceHierarchy: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="Klucz"

                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            setDeviceItem((currentItem) => ({
                                ...currentItem,
                                nwkKey: val,
                            }))
                        }
                    />

                    <Box
                        sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            onClick={onCloseButton}
                            sx={{
                                ...outlinedAcceptBlackButton,
                                alignSelf: "flex-end",
                                mt: "32px",
                                mr: "18px",
                            }}
                        >
                            Anuluj
                        </Button>
                        <Button
                            onClick={saveButtonHandler}
                            sx={{
                                ...containedGreenButton,
                                alignSelf: "flex-end",
                                mt: "32px",
                            }}
                        >
                            Zapisz
                        </Button>
                    </Box>
                </ThemeProvider>
            </Box>
        </Box>
    );
};
