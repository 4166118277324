import * as React from "react";
import {
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { InfoCard } from "../Atoms/InfoCard";
import { Device, ImportSummary } from "./Interfaces";
import { DeviceActivationStatusBox } from "./DeviceActivationStatusBox";

interface Props {
  apiUrl: string | null;
  token: string | undefined;
  fileId: number;
  onCloseButton: any;
}

export const withActivationStatus = ({
  fileId,
  token,
  apiUrl,
  onCloseButton,
}: Props) => {
  return (props: any) => {
    const getActivationStatuses = async () => {
      const response = await axios.get(
        `${apiUrl}/api/v1/device/csv/files/fileId/${fileId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const resp = response.data;
      return resp;
    };

    const { isLoading, isError, data, error } = useQuery({
      queryKey: ["activationStatuses"],
      queryFn: getActivationStatuses,
    });

    if (isLoading) {
      return <span>Loading...</span>;
    }

    if (isError) {
      alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
      return <span>Error: {error.message}</span>;
    }

    const onReactivate = () => console.log("OnReactive function");

    return (
      <DeviceActivationStatusBox
        headerText="Status aktywacji urządzeń"
        importSummary={{
          epid: data.epid,
          filename: data.filename,
          totalDevices: data.totalDevices,
          insertedDatetime: data.insertedDatetime,
        }}
        deviceList={data.rows}
        onCloseButton={onCloseButton}
        onReactivate={onReactivate}
      />
    );
  };
};
