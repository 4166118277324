import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
  Theme,
  containedGreenButton,
  outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import { SelectItem } from "../Atoms/SelectItem";
import {
  LicenseClassItem,
  ProfileItem,
  macVersionsEnum,
  regParamsRevisionEnum,
  adrAlgorithmsEnum,
  playCodecEnum,
} from "./Interfaces";

interface Props {
  headerText?: string;
  info?: string;
  onCloseButton?: any;
  onSaveButton?: any;
  licenseClasses: LicenseClassItem[];
}

export const AddProfileCard = ({
  headerText,
  info,
  onCloseButton,
  onSaveButton,
  licenseClasses,
}: Props) => {
  const [profileItem, setProfileItem] = React.useState<ProfileItem>({
    adrAlgorithmID: "default",
    classBTimeout: 0,
    classCTimeout: 0,
    factoryPresetFreqs: [],
    geolocBufferTTL: 0,
    geolocMinBufferSize: 0,
    macVersion: "1.0.0",
    maxDutyCycle: 0,
    maxEIRP: 0,
    name: "nazwa profilu",
    payloadCodec: "string",
    payloadDecoderScript: "string",
    payloadEncoderScript: "string",
    pingSlotDR: 0,
    pingSlotFreq: 0,
    pingSlotPeriod: 0,
    regParamsRevision: "A",
    rfRegion: "EU868",
    rxDROffset1: 0,
    rxDataRate2: 0,
    rxDelay1: 0,
    rxFreq2: 0,
    supports32BitFCnt: false,
    supportsClassB: false,
    supportsClassC: false,
    supportsJoin: true,
    uplinkInterval: "360s",
  });

  //console.log(licenseClasses);

  const [licenseClassId, setLicenseClassId] = useState<number>();

  /** 
  useEffect(() => {
    console.log(profileItem);
  }, [profileItem.name]);
  */
  const saveButtonHandler = () => onSaveButton(profileItem, licenseClassId);

  const filterLicenseClassId = (licenseClassName: string) => {
    const licenses: LicenseClassItem[] = licenseClasses.filter(
      (item) => item.description === licenseClassName
    );
    setLicenseClassId(licenses[0]?.id);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "80vh",
        width: "35.2vw",
        overflowY: "auto",
        overflowX: "clip",
        /** 
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        */
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box marginBottom="24px">
            <Typography variant="h5">{info}</Typography>
            {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
          </Box>

          <InputIconTextItem
            label="Nazwa profilu"
            text={profileItem?.name}
            icon={null}
            customWidth="80%"
            getValue={(val: string) =>
              setProfileItem((currentItem) => ({ ...currentItem, name: val }))
            }
          />

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={licenseClasses?.map(
                (val: LicenseClassItem) => val.description
              )}
              label="Klasa licencji"
              changeHandler={(val: string) => filterLicenseClassId(val)}
              customwidth="280px"
            />
            <Typography variant="body1" marginLeft="16px">
              Klasa licencji
            </Typography>
          </Box>

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={Object.values(macVersionsEnum).map((el) => el)}
              label="Wersja MAC"
              changeHandler={(val: string) =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  macVersion: val,
                }))
              }
            />
            <Typography variant="body1" marginLeft="16px">
              Wersja MAC
            </Typography>
          </Box>

          <InputIconTextItem
            label="Interwał up-link"
            text={profileItem?.uplinkInterval}
            icon={null}
            customWidth="80%"
            getValue={(val: string) =>
              setProfileItem((currentItem) => ({
                ...currentItem,
                uplinkInterval: val,
              }))
            }
          />

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={Object.values(regParamsRevisionEnum).map(
                (el) => el
              )}
              label="Regionalne parametry"
              changeHandler={(val: string) =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  regParamsRevision: val,
                }))
              }
              customwidth="280px"
            />
            <Typography variant="body1" marginLeft="16px">
              Ustawienia regionalne urządzenia
            </Typography>
          </Box>

          <InputIconTextItem
            label="Maksymalny EIRP"
            text={profileItem?.maxEIRP}
            icon={null}
            customWidth="80%"
            getValue={(val: number) =>
              setProfileItem((currentItem) => ({
                ...currentItem,
                maxEIRP: val,
              }))
            }
          />

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={Object.values(adrAlgorithmsEnum).map((el) => el)}
              label="Algorytm ADR"
              changeHandler={(val: string) =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  adrAlgorithmID: val,
                }))
              }
              customwidth="280px"
            />
            <Typography variant="body1" marginLeft="16px">
              Algorytm ADR
            </Typography>
          </Box>

          <Stack direction="row" spacing={1} alignItems="center">
            {profileItem.supportsJoin ? (
              <Typography variant="body1">TAK</Typography>
            ) : (
              <Typography variant="body1">NIE</Typography>
            )}

            <Switch
              checked={profileItem.supportsJoin}
              onChange={() =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  supportsJoin: !currentItem.supportsJoin,
                }))
              }
              disabled={true}
              //name={key}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#2bfece",
                  "&:hover": {
                    backgroundColor: "#2bfece",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#2bfece",
                },
              }}
            />
            <Typography variant="body1">Obsługuje OTAA ?</Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            {profileItem.supportsClassB ? (
              <Typography variant="body1">TAK</Typography>
            ) : (
              <Typography variant="body1">NIE</Typography>
            )}

            <Switch
              checked={profileItem.supportsClassB}
              onChange={() =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  supportsClassB: !currentItem.supportsClassB,
                  supportsClassC: currentItem.supportsClassC
                    ? !currentItem.supportsClassC
                    : currentItem.supportsClassC,
                }))
              }
              //name={key}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#2bfece",
                  "&:hover": {
                    backgroundColor: "#2bfece",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#2bfece",
                },
              }}
            />
            <Typography variant="body1">Obsługuje klasę B ?</Typography>
          </Stack>

          {profileItem.supportsClassB && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <InputIconTextItem
                label="Class B Timeout"
                text={profileItem?.classBTimeout}
                icon={null}
                customWidth="80%"
                getValue={(val: number) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    classBTimeout: val,
                  }))
                }
              />

              <InputIconTextItem
                label="Ping Slot DR"
                text={profileItem?.pingSlotDR}
                icon={null}
                customWidth="80%"
                getValue={(val: number) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    pingSlotDR: val,
                  }))
                }
              />
              <InputIconTextItem
                label="Ping Slot Frequency"
                text={profileItem?.pingSlotFreq}
                icon={null}
                customWidth="80%"
                getValue={(val: number) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    pingSlotFreq: val,
                  }))
                }
              />
              <InputIconTextItem
                label="Ping Slot Period"
                text={profileItem?.pingSlotPeriod}
                icon={null}
                customWidth="80%"
                getValue={(val: number) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    pingSlotPeriod: val,
                  }))
                }
              />
            </Box>
          )}

          <Stack direction="row" spacing={1} alignItems="center">
            {profileItem.supportsClassC ? (
              <Typography variant="body1">TAK</Typography>
            ) : (
              <Typography variant="body1">NIE</Typography>
            )}

            <Switch
              checked={profileItem.supportsClassC}
              onChange={() =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  supportsClassC: !currentItem.supportsClassC,
                  supportsClassB: currentItem.supportsClassB
                    ? !currentItem.supportsClassB
                    : currentItem.supportsClassB,
                }))
              }
              //name={key}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#2bfece",
                  "&:hover": {
                    backgroundColor: "#2bfece",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#2bfece",
                },
              }}
            />
            <Typography variant="body1">Obsługuje klasę C ?</Typography>
          </Stack>

          {profileItem.supportsClassC && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <InputIconTextItem
                label="Class C Timeout"
                text={profileItem?.classCTimeout}
                icon={null}
                customWidth="80%"
                getValue={(val: number) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    classCTimeout: val,
                  }))
                }
              />
            </Box>
          )}

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={Object.values(playCodecEnum).map((val) => val)}
              label="Play codec"
              changeHandler={(val: string) =>
                setProfileItem((currentItem) => ({
                  ...currentItem,
                  payloadCodec: val,
                }))
              }
              customwidth="280px"
            />
            <Typography variant="body1" marginLeft="16px">
              Play Codec
            </Typography>
          </Box>
          {profileItem.payloadCodec === "Custom JavaScript codec functions" && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <InputIconTextItem
                label="Funkcja kodująca"
                text={profileItem?.payloadEncoderScript}
                icon={null}
                customWidth="80%"
                customHeight="400px"
                multiLine={true}
                getValue={(val: string) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    payloadEncoderScript: val,
                  }))
                }
              />
              <InputIconTextItem
                label="Funkcja dekodująca"
                text={profileItem?.payloadDecoderScript}
                icon={null}
                customWidth="80%"
                customHeight="400px"
                multiLine={true}
                getValue={(val: string) =>
                  setProfileItem((currentItem) => ({
                    ...currentItem,
                    payloadDecoderScript: val,
                  }))
                }
              />
            </Box>
          )}
          <Box
            sx={{ width: "86%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onCloseButton}
              sx={{
                ...outlinedAcceptBlackButton,
                alignSelf: "flex-end",
                mt: "32px",
                mr: "18px",
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={saveButtonHandler}
              sx={{
                ...containedGreenButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Zapisz
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
