import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivateDevicesForm } from "./ActivateDevicesForm";

export default interface IFile {
  url: string;
  name: string;
}

interface Props {
  context?: string;
  apiUrl: string | null;
  token: string | undefined;
  onCloseButton: any;
  getUploadeFiles: any;

}


export const withActivateDevices = ({
  context,
  apiUrl,
  token,
  onCloseButton,
  getUploadeFiles,

}: Props) => {
  return (props: any) => {

    const [file, setFile] = useState<File | null>(null);
    const [epid, setEpid] = useState<string>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    };


    const handleEpidChange = (epid: string) => {
      setEpid(epid);
    };

    /** 
    const handleEpidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEpid(event.target.value);
    };
  */
    const handleUpload = async () => {
      if (!file || !epid) {
        alert('Please select a file and provide an EPID value.');
        return;
      }

      setIsUploading(true);

      try {
        const formData = new FormData();
        formData.append('csvFile', file);
        formData.append('epid', epid);

        await axios.put(`${apiUrl}/api/v1/device/csv/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        alert('Plik załadowany prawidłowo!');
      } catch (error) {
        console.error('Błąd ładowania pliku:', error);
        alert('Błąd ładowania pliku. Spróbuj ponownie.');
      } finally {
        setIsUploading(false);
        onCloseButton();
      }
    };

    return (
      <ActivateDevicesForm
        headerText="Aktywuj urządzenia LoraWan"
        info="Zaimportuj plik CSV z urządzeniami, zgodnie z formatem"
        onCloseButton={onCloseButton}
        epid={context === "ADMIN" ? "" : context}
        isUploading={isUploading}
        handleEpidChange={handleEpidChange}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        fileName={file ? file.name : "Nie wybrano pliku..."}

      />
    );
  };
};
