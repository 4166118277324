import React, { Component } from "react";
import LandingPage from "./Pages/LandingPage/LandingPage";
import { Logout } from "./Pages/LandingPage/Logout";
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const ProtectedRoute = ({ component, ...args }: any) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const redirectURL = `${window.location.origin}/admin`;
//const appConfigUrl = `https://tapir-provisioning-dev.misot.pl/api/v1/system/frontendConfig`;
const appConfigUrl = `${window.location.origin}/api/v1/system/frontendConfig`;

const getLicenseClassesAndProfiles = async () => {
  const response = await axios.get(
    appConfigUrl,

    {
      headers: {
        //Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  const resp = response.data;

  return resp;
};

function App() {

  //console.log(appConfigUrl);

  useEffect(() => {
    document.title = "TAPIR"
  }, [])

  const { isLoading, isError, data, error, isSuccess } = useQuery({
    queryKey: ["frontendConfig"],
    queryFn: getLicenseClassesAndProfiles,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }

  localStorage.setItem("dataPumpUrl", data.dataPumpUrl);
  localStorage.setItem("auth0ClientId", data.auth0ClientId);
  localStorage.setItem("rabbitAccessToken", data.rabbitAccessToken);
  localStorage.setItem("auth0Domain", data.auth0Domain);
  localStorage.setItem("auth0ClientScope", data.auth0ClientScope);
  localStorage.setItem("apiUrl", `${window.location.origin}`);

  /**
    localStorage.setItem("dataPumpUrl", "https://tapir-data-dev.misot.pl");
    localStorage.setItem("auth0ClientId", "9UUsBej8NwV0Ec6JJmfosc2AkJbTPU1D");
    localStorage.setItem("rabbitAccessToken", "https://epid.engi.pl/api-tapir/epid.cgi");
    localStorage.setItem("auth0Domain", "epid.eu.auth0.com");
    localStorage.setItem("auth0ClientScope", "tapir");
    localStorage.setItem("apiUrl", "https://tapir-provisioning-dev.misot.pl");
    */

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={data.auth0Domain}
        clientId={data.auth0ClientId}
        authorizationParams={{
          redirect_uri: redirectURL,
          scope: data.auth0ClientScope,
        }}
        cacheLocation="localstorage"
      >
        <Routes>
          <Route path="/" element={<ProtectedRoute component={Layout} />}>
            <Route index element={<ProtectedRoute component={LandingPage} />} />
            <Route
              path="admin"
              element={<ProtectedRoute component={LandingPage} />}
            />

            {/**  
          * <Route index element={<LandingPage />} />
          <Route path="*" element={<NoPage />} />
          */}
          </Route>

        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default App;
