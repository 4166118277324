import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { LicenseListItem } from "../Atoms/LicenseListItem";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TitleAction } from "../Atoms/TitleAction";
import { Modal } from "@mui/material";
import { withCreateProfileItem } from "./withCreateProfile";
import { LicenseProfileItem } from "./Interfaces";

const drawerWidth = 240;

const apiUrl = localStorage.getItem("apiUrl");
//const apiUrl = process.env.REACT_APP_API_SERVER_URL;
//const token = process.env.REACT_APP_API_FAKE_TOKEN;


export const ProfileManager = () => {

  const token: string | any = localStorage.getItem("idToken");

  const [isAddProfileModalOpen, setIsAddProfileModalOpen] =
    React.useState(false);

  const handleCloseAddProfileModal = () => setIsAddProfileModalOpen(false);
  const handleOpenAddProfileModal = () => setIsAddProfileModalOpen(true);

  const getLicenseClassesAndProfiles = async () => {
    const response = await axios.get(
      `${apiUrl}/api/v1/deviceProfileToLicenceClassAssignment`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = response.data;
    return resp;
  };

  const { isLoading, isError, data, error, isSuccess } = useQuery({
    queryKey: ["profiles"],
    queryFn: getLicenseClassesAndProfiles,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }

  const AddProfileForm = withCreateProfileItem({
    apiUrl: apiUrl,
    token: token,
    onCloseButton: handleCloseAddProfileModal,
    getLicensesAndProfiles: getLicenseClassesAndProfiles,
  });

  return (

    <Box

      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: 1,
        gap: 1,
        p: 3,
        minWidth: "1000px",
      }}
    >
      <TitleAction
        actionName="Dodaj profil"
        title="Lista profili "
        actionClickHandler={handleOpenAddProfileModal}
      />
      {data?.map((el: LicenseProfileItem, index: number) => (
        <LicenseListItem
          key={index}
          licenceClassId={el?.licenceClassId}
          licenceClassDescriptio={el?.licenceClassDescription}
          deviceProfileId={el?.deviceProfileId}
          deviceProfileName={el?.deviceProfileName}
          isActive={el?.isActive}
          showvertIcon={true}
        />
      ))}

      <Modal
        open={isAddProfileModalOpen}
        onClose={handleCloseAddProfileModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <AddProfileForm />
        </Box>
      </Modal>
    </Box>

  );
};
