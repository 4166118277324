import * as React from "react";
import Box from "@mui/material/Box";
import { MainManu } from "../../Components/Menu/MainManu";
import { DeviceManager } from "../../Components/DeviceManager/DeviceManager";
import { ProfileManager } from "../../Components/ProfileManager/ProfileManager";
import { LicenseClassManager } from "../../Components/LicenseClassManager/LicenseClassManager";
//import { ActivationWizard } from "../../Components/ActivationWizard/ActivationWizard";
import { useOutletContext } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LicenseManager } from "../../Components/LicenseManager/LicenseManager";
import { SingleDeviceManager } from "../../Components/DeviceManager/SingleDeviceManager";
import { UsageFilesManager } from "../../Components/UsageFilesManager/UsageFilesManager";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

//const drawerWidth = 240;

interface MenuOption {
  option: number;
  name: string;
}

type ContextType = {

  setPageName: any,
  setUserContenxtList: any,
  setIsAdmin: any,
  selectedContext: string,

}

//const apiUrl = `${window.location.origin}`;

const apiUrl = localStorage.getItem("apiUrl");


//const token = process.env.REACT_APP_API_FAKE_TOKEN;

//const token = localStorage.getItem("idToken");

const LandingPage = () => {
  const [menuOption, setMenuOption] = React.useState<MenuOption | undefined>({
    option: 1,
    name: "Import urządzeń",
  });



  const [token, setToken] = React.useState<String | any>(null);

  const { getIdTokenClaims, logout } = useAuth0();

  const [setPageName, setUserContenxtList, setIsAdmin, selectedContext, setSelectedContext] = useOutletContext<any>();

  React.useEffect(() => {
    getIdTokenClaims().then(claims => {
      const idToken: string | undefined = claims?.__raw;
      setToken(idToken);
      localStorage.setItem('idToken', `${idToken}`);
      getUserContext(idToken);
      //console.log("ID Token ==> ", claims?.__raw);
      console.log(`${window.location.origin}/logout`);
    }).catch(err => {
      console.debug("Something went wrong", err);
    })
  }, [])

  const getUserContext = (tmpToken: string | undefined) => {
    axios.get(
      `${apiUrl}/api/v1/system/context`,

      {
        headers: {
          Authorization: `Bearer ${tmpToken}`,
          "Content-Type": "application/json",
        },
      }
    ).then(response => {
      const resp = response.data;
      const contextList = resp.epids;
      //setSelectedContext(contextList[0]);
      //console.log(contextList);
      setUserContenxtList(contextList);
      const admin = resp.privileges.admin ? true : false;
      setSelectedContext(admin ? "ADMIN" : contextList[0])
      setIsAdmin(admin);


    }).catch((err: any) => {
      console.log(err);
      if (err.response.status === 401 || err.response.status === 500 || err.response.status === 403) {
        alert('Twoja sesja wygasła, za chwile zostaniesz wylogowany z systemu i przekierowany na stronę główną. Aby ponownie skorzystać z systemu musisz się ponownie zalogować ');
        localStorage.clear();
        logout({
          logoutParams: { returnTo: `${window.location.origin}/logout` },
        });
      }
    });



  };

  /**
    const getUserContext = async () => {
     
        const response = await axios.get(
          `${apiUrl}/api/v1/system/context`,
  
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const resp = response.data;
        return resp;
     
    };
   */



  React.useEffect(() => {
    setMenuOption({
      option: 1,
      name: "Import urządzeń",
    })

  }, [selectedContext]);

  React.useEffect(() => {
    const pageName: string =
      menuOption?.option == 0
        ? "Dodaj Urządzenie"
        : menuOption?.option == 1
          ? "Import urządzeń"
          : menuOption?.option == 2
            ? "Klasy licencji"
            : menuOption?.option == 3
              ? "Profile"
              : menuOption?.option == 4
                ? "Licencje"
                : "Pliki uzycia";
    setPageName(pageName);
  }, [menuOption]);

  /** 
    const { isLoading, isError, data, error, isSuccess } = useQuery({
      queryKey: ["userContext"],
      queryFn: getUserContext,
    });
  
    if (isLoading) {
      return <span>Wczytuję...</span>;
    }
  
    if (isError) {
      return <span>Błąd: {error.message}</span>;
    }
  */


  //setSelectedContext(admin ? "ADMIN" : contextList[0]);

  return (
    <>
      {token && selectedContext ?
        <Box sx={{ display: "flex" }}>
          <MainManu
            setOption={(option: number, name: string) =>
              setMenuOption({ option: option, name: name })
            }
            options={selectedContext === "ADMIN" ? ["Dodaj urządzenie", "Import urządzeń", "Klasy Licencji", "Profile", "Licencje", "Pliki uzycia"] : ["Dodaj urządzenie", "Import urządzeń"]}
          />

          {menuOption?.option == 0 && <SingleDeviceManager context={selectedContext} />}
          {menuOption?.option == 1 && <DeviceManager context={selectedContext} />}
          {menuOption?.option == 2 && <LicenseClassManager />}
          {menuOption?.option == 3 && <ProfileManager />}
          {menuOption?.option == 4 && <LicenseManager />}
          {menuOption?.option == 5 && <UsageFilesManager />}


        </Box>
        : "Wczytywanie"
      }
    </>

  );
};
export default LandingPage;
/** 
export default withAuthenticationRequired(LandingPage, {
  onRedirecting: () => (<div>Przekirowanie na stronę logowania</div>)
});
*/
