import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import "@fontsource/lato";
import {
    Theme,
    containedGreenButton,
    outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import { AccessRights } from "./Utils";



interface Props {
    accessRights?: AccessRights;
    headerText?: string;
    info?: string;
    onCloseButton?: any;
    onSaveButton?: any;
    selectRight?: any;

}

export const GetAccessTokenForm = ({
    headerText = "Generowanie Access Tokena",
    info = "Wprowadź uprawnienia dla odczytu, zapisu oraz konfiguracji",
    onCloseButton,
    onSaveButton,
    selectRight,
}: Props) => {

    /** 
    useEffect(() => {
      console.log(inputValue);
    }, [inputValue]);
    */

    return (
        <Box
            sx={{
                bgcolor: "white",
                maxHeight: "900px",
                width: "35.2vw",
                borderRadius: 4,
                padding: 3,
                boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: 3,
                    p: 2,
                }}
            >
                <ThemeProvider theme={Theme}>
                    <Box>
                        <Typography
                            variant="h4"
                            lineHeight={1}
                            letterSpacing={0.22}
                            fontSize="24px"
                        >
                            {headerText}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5">{info}</Typography>
                        {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
                    </Box>

                    <InputIconTextItem
                        label="Client ID"
                        text=""
                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            selectRight((currentItem: AccessRights) => ({
                                ...currentItem,
                                clientID: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="Client Secret"
                        text=""
                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            selectRight((currentItem: AccessRights) => ({
                                ...currentItem,
                                clientSecret: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="read access"
                        text="EPID.Katowice.*"
                        icon={null}
                        customWidth="80%"

                        getValue={(val: string) =>
                            selectRight((currentItem: AccessRights) => ({
                                ...currentItem,
                                readAccess: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="write access"
                        text="EPID.Katowice.*"
                        icon={null}
                        customWidth="80%"
                        getValue={(val: string) =>
                            selectRight((currentItem: AccessRights) => ({
                                ...currentItem,
                                writeAccess: val,
                            }))
                        }
                    />

                    <InputIconTextItem
                        label="configureaccess"
                        text="EPID.Katowice.*"
                        icon={null}
                        customWidth="80%"
                        getValue={(val: string) =>
                            selectRight((currentItem: AccessRights) => ({
                                ...currentItem,
                                configureAccess: val,
                            }))
                        }
                    />

                    <Box
                        sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            onClick={onCloseButton}
                            sx={{
                                ...outlinedAcceptBlackButton,
                                alignSelf: "flex-end",
                                mt: "32px",
                                mr: "18px",
                            }}
                        >
                            Anuluj
                        </Button>
                        <Button
                            onClick={onSaveButton}
                            sx={{
                                ...containedGreenButton,
                                alignSelf: "flex-end",
                                mt: "32px",
                            }}
                        >
                            Zapisz
                        </Button>
                    </Box>
                </ThemeProvider>
            </Box>
        </Box>
    );
};
