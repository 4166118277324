export interface ProfileItem {
  name: string; //Profile name
  macVersion: string; //Lora WAN MAC version - 1.0.0, 1.0.1 ...
  uplinkInterval: string; //This defines the expected uplink interval which the device uses for communication - 360s,
  regParamsRevision: string; //Revision of the Regional Parameters document supported by the End-Device - default A
  maxEIRP: number; //Maximum EIRP supported by the End-Device - default 0
  adrAlgorithmID: string; //ADR algorithm ID. In case this is left blank, or is configured to a non-existing ADR algorithm (plugin), then it falls back to 'default'- default default
  supportsJoin: boolean; //End-Device supports Join (OTAA) or not (ABP) - default true

  //Class B specyfic settings
  supportsClassB: boolean; //End-Device supports Class B - default false
  classBTimeout: number; //Maximum delay for the End-Device to answer a MAC request or a confirmed DL frame (mandatory if class B mode supported) - default 0
  pingSlotDR: number; // Mandatory if class B mode supported. - default 0
  pingSlotFreq: number; // Mandatory if class B mode supported. - default 0
  pingSlotPeriod: number; // Mandatory if class B mode supported. - default 0

  //Class C specyfic settings
  supportsClassC: boolean; // End-Device supports Class C - default false
  classCTimeout: number; // Maximum delay for the End-Device to answer a MAC request or a confirmed DL frame (mandatory if class C mode supported) - default 0

  //Non OTTA - ABB specyfic settings
  rxDROffset1: number; // N/A - RX1 data rate offset (mandatory for ABP) - default 0
  rxDataRate2: number; // N/A - RX2 data rate (mandatory for ABP). - default 0
  rxDelay1: number; // N/A - Class A RX1 delay (mandatory for ABP). - default 0
  rxFreq2: number; // N/A - RX2 channel frequency (mandatory for ABP) - default 0
  factoryPresetFreqs: string[]; // N/A - List of factory-preset frequencies (mandatory for ABP) - no default

  rfRegion: string; //RF region name - default EU868

  //Codec settings
  payloadCodec: string; //Payload codec. Leave blank to disable the codec feature. - ENUM - Custom JavaScript codec functions
  payloadDecoderScript: string; //Payload decoder script. Depending the codec, it is possible to provide a script which implements the decoder function.
  payloadEncoderScript: string; //Payload encoder script. Depending the codec, it is possible to provide a script which implements the encoder function.

  //Other settings - TBC
  geolocBufferTTL: number; // Geolocation buffer TTL (in seconds). When > 0, uplink RX meta-data will be stored in a buffer so that the meta-data of multiple uplinks can be used for geolocation. - default 0
  geolocMinBufferSize: number; //Geolocation minimum buffer size. When > 0, geolocation will only be performed when the buffer has at least the given size. - default 0
  maxDutyCycle: number; //Maximum duty cycle supported by the End-Device. - default 0
  supports32BitFCnt: boolean; //
}

export interface LicenseClassItem {
  id: number;
  insertedDateTime: string;
  updatedDateTime: string;
  description: string;
  isActive: boolean;
}

export interface LicenseClasses {
  licenseProfileItems: Array<LicenseClassItem>;
  seLicenseClass: any;
}

export interface LicenseItem {
  id: number;
  description: string;
}

export interface LicenseProfileClassAssignmentItem {
  licenceClassId: number;
  deviceProfileId: string;
  isActive: boolean;
}

export interface LicenseProfileItem {
  licenceClassId: number;
  licenceClassDescription: string;
  deviceProfileId: string;
  deviceProfileName: string;
  isActive: boolean;
}

export enum macVersionsEnum {
  ver0 = "1.0.0",
  ver1 = "1.0.1",
  ver2 = "1.0.2",
  ver3 = "1.0.3",
  ver4 = "1.0.4",
  ver5 = "1.1.0",
}

export enum adrAlgorithmsEnum {
  lora = "Default ADR algorithm (LoRa only)",
  lorafhss = "LoRa & LR-FHSS ADR algorithm",
  fhss = "LR-FHSS only ADR algorithm",
}

export enum regParamsRevisionEnum {
  A = "A",
  B = "B",
  rp0020 = "RP002-1.0.0",
  rp0021 = "RP002-1.0.1",
  rp0022 = "RP002-1.0.2",
  rp0023 = "RP002-1.0.3",
}

export enum playCodecEnum {
  "Brak" = "",
  "JavaScript codec" = "Custom JavaScript codec functions",
}
