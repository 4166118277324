import * as React from "react";
import { createTheme } from "@mui/material/styles";
import "@fontsource/lato";
import "@fontsource/lato/900.css";
import { Interface } from "readline";
import { styled } from "@mui/material/styles";

export const NaviStyle = {
  fontFamily: "Lato",
  fontSize: 12,
  fontWeight: 900,
  color: "#9b9b9b",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.25,
  letterSpacing: 0.11,
  textTransform: "initial",
  mr: -1.5,
};

declare module "@mui/material/styles" {
  interface Theme {
    breakpoints: {
      values: {
        mobile: number;
        tablet: number;
        laptop: number;
        desktop: number;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    breakpoints?: {
      values?: {
        mobile?: number;
        tablet?: number;
        laptop?: number;
        desktop?: number;
      };
    };
  }
}

export const containedGreenButton = {
  borderRadius: 16,
  minWidth: 112,
  height: 43,
  border: 0,
  borderColor: "#363636",
  color: "#363636",
  backgroundColor: "#2bfece",
  fontSize: 16,
  fontStyle: "normal",
  fontFamily: "Lato",
  fontWeight: 900,
  lineHeight: 4.38,
  letterSpacing: 0.14,
  textTransform: "initial",
  "&:hover": {
    cursor: "pointer",
    color: "#363636",
    borderColor: "#363636",
  },
};

export const outlinedAcceptBlackButton = {
  borderRadius: "24px",
  width: 112,
  height: 43,
  border: "solid 2px",
  borderColor: "#363636",
  color: "#363636",
  backgroundColor: "#fff",
  fontSize: 16,
  fontFamily: "Lato",
  fontWeight: 900,
  fontStyle: "normal",
  lineHeight: 1.14,
  letterSpacing: 0.13,
  textTransform: "initial",
  "&:hover": {
    cursor: "pointer",
    color: "#363636",
    borderColor: "#363636",
  },
};

export const orrangeButton = {
  borderRadius: 16,
  minWidth: 112,
  height: 44,
  border: "solid 2px #f29a26",
  borderColor: "#f29a26",
  color: "#fff",
  backgroundColor: "#f29a26",
  fontSize: 16,
  fontStyle: "normal",
  fontFamily: "Lato",
  fontWeight: 900,
  lineHeight: 4.38,
  letterSpacing: 0.14,
  textTransform: "initial",
  "&:hover": {
    cursor: "pointer",
    color: "#363636",
    borderColor: "#363636",
  },
};

export const outlinedCancelGrayButton = {
  borderRadius: "24px",
  width: 112,
  height: 43,
  border: "solid 2px",
  borderColor: "#e6e6e6",
  color: "#363636",
  backgroundColor: "#fff",
  fontSize: 16,
  fontFamily: "Lato",
  fontWeight: 900,
  fontStyle: "normal",
  lineHeight: 1.14,
  letterSpacing: 0.13,
  textTransform: "initial",
  "&:hover": {
    cursor: "pointer",
    color: "#363636",
    borderColor: "#363636",
  },
};

export const Theme = createTheme({
  spacing: 1,
  breakpoints: {
    values: {
      mobile: 360,
      tablet: 768,
      laptop: 1280,
      desktop: 1920,
    },
  },

  typography: {
    subtitle1: {
      fontFamily: "Lato",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.11,
      color: "#9b9b9b",
      textTransform: "uppercase",

      allVariants: {
        "&": {
          display: "inline",
        },
      },
    },

    h2: {
      fontFamily: "Lato",
      fontWeight: 900,
      fontSize: 48,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.17,
      letterSpacing: "normal",
      color: "#363636",
    },
    h3: {
      fontFamily: "Lato",
      fontSize: 34,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.06,
      letterSpacing: "normal",
      color: "#363636",
      textDecoration: "underline",
    },
    h4: {
      fontFamily: "Lato",
      fontSize: 24,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.18,
      color: "#343433",
    },
    h5: {
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#363636",
    },
    h6: {
      fontFamily: "Lato",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.11,
      color: "#363636",
      // textDecoration: "underline",
      textTransform: "uppercase",
    },

    body1: {
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.07,
      letterSpacing: 0.13,
      color: "#363636",
      "&:hover": {
        opacity: 1,
      },
    },
    body2: {
      fontFamily: "Lato",
      fontSize: 16,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.94,
      letterSpacing: 0.14,
      color: "#363636",
    },
    subtitle2: {
      fontFamily: "Lato",
      fontSize: 18,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: 0.15,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#363636",
        borderColor: "#363636",
      },
    },
  },
});
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
