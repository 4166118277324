import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
  Theme,
  containedGreenButton,
  outlinedAcceptBlackButton,
  VisuallyHiddenInput,
  outlinedCancelGrayButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Link, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  epid?: string;
  headerText?: string;
  info?: string;
  onCloseButton?: any;
  onSaveButton?: any;
  isUploading: boolean;
  handleEpidChange: any;
  handleUpload: any;
  handleFileChange: any;
  fileName?: string;
}

interface IFile {
  url: string;
  name: string;
}

export const ActivateDevicesForm = ({
  epid,
  headerText,
  info,
  onCloseButton,
  onSaveButton,
  isUploading,
  handleEpidChange,
  handleFileChange,
  handleUpload,
  fileName,
}: Props) => {
  const [isActive, setIsActive] = useState(true);
  const [inputValue, setinputValue] = useState("");

  /** 
  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);
  */
  const saveButtonHandler = () =>
    onSaveButton.mutate({
      description: inputValue,
      isActive: isActive,
    });

  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "900px",
        width: "35.2vw",
        /** 
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        */
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">{info}</Typography>
            {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
          </Box>

          <InputIconTextItem
            label="EPID"
            text={epid ? epid : "np. JTEAM"}
            disabled={epid ? true : false}
            icon={null}
            customWidth="80%"
            getValue={handleEpidChange}
            type="text"
          />
          <Box sx={{ display: "flex" }}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                backgroundColor: "#f2f2f2",
                color: "#363636",
                mr: "16px",
              }}
            >
              Wybierz plik
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            {!isUploading ? (
              <Typography variant="body2" lineHeight={1} letterSpacing={0.22}>
                {fileName}
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>

          <Link
            href="https://tapir-provisioning-dev.misot.pl/example.csv"
            underline="none"
          >
            <Typography
              variant="body2"
              lineHeight={1}
              letterSpacing={0.22}
              sx={{ textDecoration: "underline" }}
            >
              Pobierz wzór pliku importu
            </Typography>
          </Link>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onCloseButton}
              sx={{
                ...outlinedAcceptBlackButton,
                alignSelf: "flex-end",
                mt: "32px",
                mr: "18px",
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={handleUpload}
              disabled={fileName === "Nie wybrano pliku..." ? true : false}
              sx={{
                ...containedGreenButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Zapisz
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
