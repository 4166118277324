import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "@fontsource/lato";
import { Theme } from "../../Components/Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface OptionItem {
  id: number;
  value: string;
}

interface Props {
  label?: string;
  availOptions?: string[];
  customwidth?: string;
  changeHandler?: any;
}

export const SelectItem = ({
  label,
  availOptions,
  changeHandler,
  customwidth = "180px",
}: Props) => {
  const [itemState, setItemState] = useState<string>(availOptions ? availOptions[0] : "");

  const [itemId, setItemId] = useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setItemState(event.target.value as string);
    changeHandler(event.target.value as string);
  };

  useEffect(() => {
    changeHandler(availOptions ? availOptions[0] : "");
  }, []);

  const selectStyle = {
    width: customwidth,
    height: "55px",
    borderRadius: "45px",
    backgroundColor: "#fff",
    boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
    //".MuiOutlinedInput-notchedOutline": { border: 2 },

    // ".MuiSelect-iconOutlined": { fontSize: 36, color: "#363636" },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "yellow",
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
  };

  return (
    <FormControl>
      <ThemeProvider theme={Theme}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="hourFrom"
          variant="outlined"
          name="hourFrom"
          value={itemState}
          label={label}
          onChange={(event) => handleChange(event)}
          inputProps={{}}
          IconComponent={ExpandMoreIcon}
          sx={selectStyle}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "#a9ce50",
                  opacity: 0.5,
                },
                "& .MuiMenuItem-root:hover": {
                  //backgroundColor: "#9d9d9d"
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  //backgroundColor: "#9d9d9d"
                },
                borderRadius: "20px",
              },
            },
          }}
        >
          {availOptions?.map((item, i) => {
            return (
              <MenuItem value={item} key={i}>
                <Typography variant="body1">{item}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </ThemeProvider>
    </FormControl>
  );
};
