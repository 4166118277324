import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { IconMenu } from "./IconMenu";

interface Props {
  licenceClassId: number;
  licenceClassDescriptio: string;
  deviceProfileId: string;
  deviceProfileName: string;
  isActive: boolean;
  showvertIcon: boolean;
  isProfile?:boolean;
}

export const LicenseListItem = ({
  isProfile=true,
  licenceClassId,
  licenceClassDescriptio,
  deviceProfileId,
  deviceProfileName,
  isActive = true,
  showvertIcon = true,
}: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {showvertIcon && (
          <Box
            sx={{
              // bgcolor: 'black',
              ml: "16px",
              width: "10%",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#f2f2f2",
                width: 44,
                height: 44,
              }}
            >
              <MapOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            ml: "2%",
            width: "20%",
          }}
        >
          <Typography variant="h5">{isProfile ? "Nazwa profilu" : "Data utworzenia"}</Typography>
          <Typography variant="h5" fontWeight={900}>
            {deviceProfileName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "40%",
            }}
          >
            <Typography variant="h5" noWrap>
              Klasa licencji
            </Typography>
            <Typography variant="h5" fontWeight={900} noWrap>
              {licenceClassDescriptio}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "40%",
            }}
          >
            <Typography variant="h5">Aktywny ?</Typography>
            <Typography variant="h5" fontWeight={900}>
              {isActive ? "Tak" : "Nie"}
            </Typography>
          </Box>

          <Box
            sx={{
              mr: "24px",
            }}
          >
            <IconMenu />
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
