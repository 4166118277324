import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import PolylineIcon from "@mui/icons-material/Polyline";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DevicesIcon from "@mui/icons-material/Devices";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import PetsIcon from "@mui/icons-material/Pets";
import { useOutletContext } from "react-router-dom";

const drawerWidth = 240;

/** 
interface MenuOption {
  setOption: (option: number, name: string) => void;
}
*/

interface Props {
  setOption: (option: number, name: string) => void;
  options: string[],
}

export const MainManu = ({ setOption, options }: Props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedContext] = useOutletContext<any>();

  /**
  React.useEffect(() => {

    console.log(selectedContext);
  }, [selectedContext]);
   */

  //interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  // window?: () => Window;
  //}

  const drawer = (
    <Box>
      <ThemeProvider theme={Theme}>
        <List>
          <ListItem>
            <Typography
              variant="h4"
              textTransform="uppercase"
              marginRight="10px"
            >
              Tapir
            </Typography>
            <PetsIcon />
          </ListItem>
          {options.map(
            (text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => setOption(index, text)}>
                  <ListItemIcon>
                    {text === "Klasy licencji" ? (
                      <CreateIcon />
                    ) : text === "Profile" ? (
                      <PolylineIcon />
                    ) : text === "Licencje" ? (
                      <CreateNewFolderIcon />
                    ) : text === "Dodaj urządzenie" ? (
                      <SpeakerPhoneIcon />)
                      : text === "Import urządzeń" ? (
                        <DevicesIcon />)
                        : (<DevicesIcon />
                        )}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h5">{text}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </ThemeProvider>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          //container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
