import * as React from "react";
import Box from "@mui/material/Box";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TitleAction } from "../Atoms/TitleAction";
import { FileListItem } from "../Atoms/FileListItem";


//const token = process.env.REACT_APP_API_FAKE_TOKEN;



export const UsageFilesManager = () => {
  const apiUrl = localStorage.getItem("dataPumpUrl");

  const token: string | any = localStorage.getItem("idToken");

  const downloadFile = async (fileName: string) => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/mdr/files/${fileName}`, {

        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Wystą[oł błąd z pobraniem pliku]:', error);
      // Handle error, show error message, etc.
    }
  };

  const getUsageFiles = async () => {
    const response = await axios.get(
      `${apiUrl}/api/v1/mdr/files`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = response.data;
    return resp;
  };

  const { isLoading, isError, data, error, isSuccess } = useQuery({
    queryKey: ["usageFiles"],
    queryFn: getUsageFiles,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }


  return (

    <Box

      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: 1,
        gap: 1,
        p: 3,
        minWidth: "700px",

      }}
    >
      <TitleAction
        actionName="Dodaj urządzenie"
        title="Pobierz rekordy uzycia"
        isButton={false}
      />


      {data?.map((el: string, index: number) => (
        <FileListItem
          importFileName={el}
          onClickIcon={downloadFile}
        />
      ))}


    </Box>

  );
};
