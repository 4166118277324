import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import axios from "axios";
//import { AddLicenseClassBox } from "./AddLicenseClassBox";
import { AddProfileCard } from "./AddProfileCard";
import { ProfileItem, LicenseClassItem } from "./Interfaces";

interface ProfileLicense {
  profile: ProfileItem;
  licenseId: number;
}

interface ProfileLicenseAssigment {
  licenceClassId: number;
  deviceProfileId: string;
  isActive: boolean;
}

interface Props {
  apiUrl: string | null;
  token: string | undefined;
  onCloseButton: any;
  getLicensesAndProfiles?: any;
}

export const withCreateProfileItem = ({
  apiUrl,
  token,
  onCloseButton,
  getLicensesAndProfiles,
}: Props) => {
  return (props: any) => {
    const [licenseId, setLicenseId] = useState(1);

    const postdeviceProfile = async (item: ProfileLicense) => {

      const { data } = await axios.post(
        `${apiUrl}/api/v1/deviceProfile`,
        item.profile,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const profileLicenseAssigmentItem: ProfileLicenseAssigment = {
        licenceClassId: item.licenseId,
        deviceProfileId: data.deviceProfileId,
        isActive: true,
      };
      return profileLicenseAssigmentItem;
    };

    const postLicenseAndClassAssignment = async (
      profileLicenseClassAssignmentItem: ProfileLicenseAssigment
    ) => {
      const { data } = await axios.post(
        `${apiUrl}/api/v1/deviceProfileToLicenceClassAssignment`,
        profileLicenseClassAssignmentItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //return data
    };

    const getLicenseClasses = async () => {
      const response = await axios.get(
        `${apiUrl}/api/v1/licenceClass`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const resp = response.data;
      return resp;
    };

    const query = useQuery({
      queryKey: ["profiles"],
      queryFn: getLicensesAndProfiles,
    });

    const queryClient = useQueryClient();

    const deviceProfileMutation = useMutation({
      mutationFn: postdeviceProfile,
      onSuccess: (profileLicenseClassAssignmentItem) => {
        //console.log(data);
        licenseAndClassAssignmentMutation.mutate(
          profileLicenseClassAssignmentItem
        );
      },
      onError: (error, variables, context) => {
        console.log(error)
        alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
        return <span>Error: {error.message}</span>;
      }
    });

    const licenseAndClassAssignmentMutation = useMutation({
      mutationFn: postLicenseAndClassAssignment,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["profiles"] });
        onCloseButton();
      },
      onError: (error, variables, context) => {
        console.log(error)
        alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
        return <span>Error: {error.message}</span>;
      }
    });

    const { isLoading, isError, data, error } = useQuery({
      queryKey: ["licenses"],
      queryFn: getLicenseClasses,
    });

    if (isLoading) {
      return <span>Loading...</span>;
    }

    if (isError) {
      alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
      return <span>Error: {error.message}</span>;
    }

    const onSaveProfile = (profile: ProfileItem, licenseClassId: number) => {
      setLicenseId(licenseClassId);
      const body = {
        profile: profile,
        licenseId: licenseClassId,
      };
      deviceProfileMutation.mutate(body);
    };

    //console.log(data);

    return (
      <AddProfileCard
        headerText="Dodaj profil"
        info="Wprowadź informacje dotyczące profilu"
        onCloseButton={onCloseButton}
        onSaveButton={onSaveProfile}
        licenseClasses={data.filter(
          (el: LicenseClassItem) => el.isActive === true
        )}
      />
    );
  };
};
