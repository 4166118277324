import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import {
  Theme,
  containedGreenButton,
  outlinedAcceptBlackButton,
} from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Stack, Switch } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import { SelectItem } from "../Atoms/SelectItem";
import { LicenseClassItem } from "../ProfileManager/Interfaces";

interface LicenseItem {
  trxId: string;
  epid: string;
  licenceClassId: number;
  quantity: number;
}

interface Props {
  headerText?: string;
  info?: string;
  onCloseButton?: any;
  onSaveButton?: any;
  licenceClasses: LicenseClassItem[];
}

export const AddLicenceForm = ({
  headerText,
  info,
  onCloseButton,
  onSaveButton,
  licenceClasses,
}: Props) => {
  const [isActive, setIsActive] = useState(true);
  const [inputValue, setinputValue] = useState("");
  const [licenseClassId, setLicenseClassId] = useState<number>();
  const filterLicenseClassId = (licenseClassName: string) => {
    const licenses: LicenseClassItem[] = licenceClasses.filter(
      (item) => item.description === licenseClassName
    );
    setLicenseClassId(licenses[0].id);
  };

  const [licenceItem, setLicenseItem] = React.useState<LicenseItem>({
    trxId: "000",
    epid: "EPID",
    licenceClassId: 0,
    quantity: 0,
  });
  /** 
  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);
  */
  const saveButtonHandler = () => {
    onSaveButton(licenceItem, licenseClassId);
  };
  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "900px",
        width: "35.2vw",
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">{info}</Typography>
            {/**<Typography variant="bodySmallBlack">{info}</Typography> */}
          </Box>

          <InputIconTextItem
            label="Epid"
            text="np. JTEAM"
            icon={null}
            customWidth="80%"
            getValue={(val: string) =>
              setLicenseItem((currentItem) => ({
                ...currentItem,
                epid: val,
              }))
            }
          />

          <Box
            sx={{
              width: "86%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectItem
              availOptions={licenceClasses?.map(
                (val: LicenseClassItem) => val.description
              )}
              label="Klasa licencji"
              changeHandler={(val: string) => filterLicenseClassId(val)}
              customwidth="280px"
            />
            <Typography variant="body1" marginLeft="16px">
              Klasa licencji
            </Typography>
          </Box>
          <InputIconTextItem
            label="Ilość"
            text={0}
            icon={null}
            customWidth="80%"
            type="number"
            getValue={(val: number) =>
              setLicenseItem((currentItem) => ({
                ...currentItem,
                quantity: val,
              }))
            }
          />

          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onCloseButton}
              sx={{
                ...outlinedAcceptBlackButton,
                alignSelf: "flex-end",
                mt: "32px",
                mr: "18px",
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={saveButtonHandler}
              sx={{
                ...containedGreenButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Zapisz
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
