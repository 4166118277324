import * as React from "react";

//import "@fontsource/lato";
//import "@fontsource/montserrat";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import useOnClickOutside from "../Atoms/useOnClickOutside";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface Props {
  options?: string[];
}

//const options = ["Zobacz / edytuj profil", "Usuń profil"]

export const IconMenu = ({
  options = ["Zobacz / edytuj", "Usuń"],
}: /** 
  optionSelected,
  onClickHistory,
  onClickPreview,
  onClickDelete,
  onClickStatusChange,
  onClieckLeadView,
  onClickNotesView,
  */
Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [openMenu, setOpenMenu] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const suggestionRef = React.createRef();
  useOnClickOutside(suggestionRef, () => {
    if (openMenu === true) {
      setOpenMenu(false);
      return;
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const stateAction = event.currentTarget;
    setAnchorEl(stateAction);
    if (openMenu === false) {
      setOpenMenu(true);
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    //Tutaj sobie obsłuż option Selected
    /** 
    if (option === "Edycja dobra") {
      //optionSelected();
      return;
    }
    if (option === "Podgląd dobra") {
      //onClickPreview();
      return;
    }
    if (option === "Usuń dobro") {
      //onClickDelete();
      return;
    }
    if (option === "Zmień status") {
      //onClickStatusChange();
      return;
    }
    if (option === "Podgląd leada") {
      //onClieckLeadView();
      return;
    }

    if (option === "Notatki") {
      //onClickNotesView();
      return;
    }

    //onClickHistory(true);
    */
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        //ref={suggestionRef}
      >
        <Avatar
          sx={{
            bgcolor: "#2bfece",
            width: 44,
            height: 44,
          }}
        >
          <MoreVertIcon
            sx={{
              fontSize: 26,
              color: "#363636",
            }}
          />
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            borderRadius: "14px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  bgcolor: "#2bfece",
                  width: "20px",
                  height: "20px",
                }}
              >
                {option === "Usuń" ? (
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: "#363636",
                    }}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: "#363636",
                    }}
                  />
                )}
              </Avatar>
            </ListItemIcon>
            <Typography variant="h5" fontWeight={900}>
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
