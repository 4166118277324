import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { LicensesHorizontalCard } from "../Atoms/LicensesHorizontalCard";
import { TitleAction } from "../Atoms/TitleAction";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
//import { LicenseListItem } from "../Atoms/LicenseListItem";
import { LicenseStatsListItem } from "../Atoms/LicenseStatsListItem";
import { Modal } from "@mui/material";
import { AddLicenceForm } from "./AddLicenceForm";
import { withAddLicenses } from "./withAddLicences";

const apiUrl = localStorage.getItem("apiUrl");
//const token = process.env.REACT_APP_API_FAKE_TOKEN;

interface LicenseStatsItem {
  epid: string;
  licenceStatus: string;
  licenceClassId: number;
  licenceClassDescription: string;
  amountPerEpid: number;
  amountPerLicenceClass: number;
  amountPerStatus: number;
}


export const LicenseManager = () => {

  const token: string | any = localStorage.getItem("idToken");

  const [isAddLicenseModalOpen, setIsAddLicenseModalOpen] =
    React.useState(false);

  const handleCloseAddLicenseModal = () => setIsAddLicenseModalOpen(false);
  const handleOpenAddLicenseModal = () => setIsAddLicenseModalOpen(true);

  const getLicenses = async () => {
    const response = await axios.get(
      `${apiUrl}/api/v1/licence/stats`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resp = response.data;
    return resp;
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["licenses"],
    queryFn: getLicenses,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
    return <span>Error: {error.message}</span>;
  }

  const CreateLicenseForm = withAddLicenses({
    apiUrl: apiUrl,
    token: token,
    onCloseButton: handleCloseAddLicenseModal,
    getLicenses: getLicenses,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: 1,
        gap: 1,
        p: 3,
        maxWidth: "1500px",
      }}
    >
      <TitleAction
        actionName="Dodaj licencje"
        title="Raport Licencji"
        actionClickHandler={handleOpenAddLicenseModal}
      />
      <LicensesHorizontalCard
        unUsedLicenses={data?.reduce(
          (acc: number, current: LicenseStatsItem) =>
            current.licenceStatus === "New"
              ? current.amountPerStatus + acc
              : acc,
          0
        )}
        usedLicences={data?.reduce(
          (acc: number, current: LicenseStatsItem) =>
            current.licenceStatus === "Activated"
              ? current.amountPerStatus + acc
              : acc,
          0
        )}
      />
      ;
      {data?.map((el: LicenseStatsItem, index: number) => (
        <LicenseStatsListItem
          key={index}
          epid={el.epid}
          liceceClassDescription={el.licenceClassDescription}
          licenseStatus={el.licenceStatus}
          amountPerEpid={el.amountPerEpid}
          amountPerLicenceClass={el.amountPerLicenceClass}
          amountPerStatus={el.amountPerStatus}
        />
      ))}
      <Modal
        open={isAddLicenseModalOpen}
        onClose={handleCloseAddLicenseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <CreateLicenseForm />
        </Box>
      </Modal>
    </Box>
  );
};
