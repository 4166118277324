import * as React from "react";
import {
    useQuery,
    useMutation,
    useQueryClient,

} from "@tanstack/react-query";
import axios from "axios";
import { AddSingleDeviceForm } from "./AddSingleDeviceForm";
import { DeviceItem, Profile } from "./Interfaces";


interface Props {
    epid?: string,
    apiUrl: string | null;
    token: string | undefined;
    onCloseButton: any;

}

export const withCreateSingleDevice = ({
    epid,
    apiUrl,
    token,
    onCloseButton,

}: Props) => {
    return (props: any) => {

        const getProfiles = async () => {
            const response = await axios.get(
                `${apiUrl}/api/v1/deviceProfile/profile`,

                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const resp = response.data;
            return resp;
        };

        const postSingleDevice = async (item: DeviceItem) => {
            try {
                const { data } = await axios.post(`${apiUrl}/api/v1/device`, item, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
            }
            catch (error: any) {
                alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
                return <span>Error: {error.message}</span>;
            }
        };

        const mutation = useMutation({
            mutationFn: postSingleDevice,
            onSuccess: () => {
                //queryClient.invalidateQueries({ queryKey: ["licenseClasses"] });
                onCloseButton();
            },
        });

        const { isLoading, isError, data, error } = useQuery({
            queryKey: ["profilesList"],
            queryFn: getProfiles,
        });

        if (isLoading) {
            return <span>Loading...</span>;
        }

        if (isError) {
            alert(`Wystąpił błąd aplikacji. Szczegóły: ${error.message}`);
            return <span>Error: {error.message}</span>;
        }


        return (
            <AddSingleDeviceForm
                epid={epid === "ADMIN" ? "" : epid}
                headerText="Aktywuj urządzenie"
                info="Wprowadź informacje dotyczące urządzenia"
                onCloseButton={onCloseButton}
                onSaveButton={mutation}
                profilesList={data.result}
            />
        );
    };
};
