import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
//śimport "@fontsource/montserrat";
import { Theme } from "../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { debounce } from "@mui/material";

const delay = 1000;

interface Props {
  id?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  text?: string | number;
  //text2?: string | number;
  icon?: any;
  customWidth?: string;
  customHeight?: string;
  multiLine?: boolean;
  minRows?: number;
  maxRows?: number;
  error?: boolean;
  helperText?: string;
  type?: string;
  getValue?: any;
}

export const InputIconTextItem = ({
  id,
  label,
  name,
  disabled,
  placeholder,
  text,
  //text2,
  icon,
  customWidth = "34.7%",
  customHeight = "55px",
  multiLine = false,
  minRows,
  maxRows,
  error,
  helperText,
  getValue,
  type = "text",
}: Props) => {
  const [value, setValue] = React.useState(text);

  /** 
  const [debounceValue] = useDebounce(value, delay);

  useEffect(() => {
    getValue && getValue(debounceValue);
  }, [debounceValue, getValue]);

  
  const handleChange = (event: any): any => {
    const tmpValue = event.target.value;
    event.preventDefault();
    setValue(event.target.value);
  };
*/

  useEffect(() => {
    getValue && getValue(value);
  }, [value]);

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <Paper
        component="form"
        sx={{
          p: "10px 16px 2px 24px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: customWidth,
          minHeight: customHeight,
          borderRadius: "45px",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1">{label}</Typography>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              fontFamily: "Lato",
              fontSize: 14,
              color: "#363636",
              fontWeight: "normal",
              width: "90%",
              backgroundColor: "#fff",
            }}
            id={id}
            type={type}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            defaultValue={value}
            name={name}
            value={value}
            //error={isEmail ? !isValidEmail : error}
            //error={"tarararararar"}
            //helperText={!isValidEmail ? "Nieprawidłowy email" : ""}
            disabled={disabled}
            //onChange={(event) => debounce(handleChange(event), 1000)}
            onChange={(event) => setValue(event.target.value)}
            multiline={multiLine}
            onKeyDown={keyPress}
            inputProps={{
              "aria-label": "wprowadź dane",
            }}
            error={error}
            //helpertext={helperText}
          />
        </Box>
        {icon && (
          <IconButton
            type="button"
            sx={{
              bgcolor: "#2bfece",
              width: "28px",
              height: "28px",
              mt: -5,
              alignSelf: "center",
            }}
            aria-label="search"
            onClick={() => {
              //setValue(null);
            }}
          >
            {icon}
          </IconButton>
        )}
      </Paper>
    </ThemeProvider>
  );
};
