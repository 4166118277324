import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import React from "react";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import { FormLabel } from "@mui/material";
import classes from "./BillingHorizonalCards.module.css";

interface Props {
  unUsedLicenses: number;
  usedLicences: number;
}

export const LicensesHorizontalCard = ({
  unUsedLicenses = 0,
  usedLicences = 0,
}: Props) => {
  const icon1 = (
    <SavingsOutlinedIcon
      sx={{
        fontSize: 30,
        color: "#363636",
        bgcolor: "#2bfece",
        width: "86px",
        height: "77px",
        borderRadius: "26px",
        ml: "31px",
        mt: "26px",
      }}
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "311px",
          bgcolor: "white",
          height: "131px",
          borderRadius: "25px",
        }}
      >
        <Box>
          <SavingsOutlinedIcon
            sx={{
              fontSize: 30,
              color: "#363636",
              bgcolor: "#2bfece",
              width: "86px",
              height: "77px",
              borderRadius: "26px",
              ml: "31px",
              mt: "26px",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "120px",
            height: "81px",
            bgcolor: "white",
            mt: "21px",
            ml: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormLabel
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: "900",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.22",
              letterDpacing: "0.16px",
              textAlign: "left",
              color: "#9d9d9d",
            }}
          >
            Wszystkie
          </FormLabel>
          <FormLabel
            sx={{
              fontSize: "26px",
              fontFamily: "Lato",
              letterSpacing: "0.31px",
              color: "#363636",
            }}
          >
            {usedLicences + unUsedLicenses}
          </FormLabel>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "311px",
          bgcolor: "white",
          height: "131px",
          borderRadius: "25px",
          ml: "26px",
        }}
      >
        <Box>
          <CreditScoreOutlinedIcon
            sx={{
              fontSize: 30,
              color: "#363636",
              bgcolor: "#2bfece",
              width: "86px",
              height: "77px",
              borderRadius: "26px",
              ml: "31px",
              mt: "26px",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "120px",
            height: "81px",
            bgcolor: "white",
            mt: "21px",
            ml: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormLabel
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: "900",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.22",
              letterDpacing: "0.16px",
              textAlign: "left",
              color: "#9d9d9d",
            }}
          >
            Wykorzystane
          </FormLabel>
          <FormLabel
            sx={{
              fontSize: "26px",
              fontFamily: "Lato",
              letterSpacing: "0.31px",
              color: "#363636",
            }}
          >
            {usedLicences}
          </FormLabel>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "311px",
          bgcolor: "white",
          height: "131px",
          borderRadius: "25px",
          ml: "26px",
        }}
      >
        <Box>
          <AddCardOutlinedIcon
            sx={{
              fontSize: 30,
              color: "#363636",
              bgcolor: "#2bfece",
              width: "86px",
              height: "77px",
              borderRadius: "26px",
              ml: "31px",
              mt: "26px",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "120px",
            height: "81px",
            bgcolor: "white",
            mt: "21px",
            ml: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormLabel
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: "900",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.22",
              letterDpacing: "0.16px",
              textAlign: "left",
              color: "#9d9d9d",
            }}
          >
            Niewykorzystane
          </FormLabel>
          <FormLabel
            sx={{
              fontSize: "26px",
              fontFamily: "Lato",
              letterSpacing: "0.31px",
              color: "#363636",
            }}
          >
            {unUsedLicenses}
          </FormLabel>
        </Box>
      </Box>
    </Box>
  );
};
